body {
  font-size: 1rem;
  line-height: 1.5;
  font-family: Soehne, Helvetica, Segoe UI, sans-serif;
  --tw-bg-opacity: 1;
  background-color: rgba(var(--colors-bg-default-rgb), var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(var(--colors-fg-default-rgb), var(--tw-text-opacity));
}

ul:not([class*="not-prose"]) li:not([class*="not-prose"]) {
  position: relative;
  list-style-type: none;
  margin-bottom: 0.75rem;
  padding-left: 1.8em;
}

ul:not([class*="not-prose"]) li:not([class*="not-prose"]):last-of-type {
  margin-bottom: 0px;
}

ul:not([class*="not-prose"]) li:not([class*="not-prose"])::before {
  display: inline-block;
  position: absolute;
  top: 0.7em;
  left: 0px;
  height: 0.0625rem;
  --tw-bg-opacity: 1;
  background-color: rgba(var(--colors-brand-primary-base-rgb), var(--tw-bg-opacity));
  border-radius: 9999px;
  content: var(--tw-content);
  width: 1rem;
}

ul:not([class*="not-prose"]) ul:not([class*="not-prose"]) {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

body.dark {
  /* invert background colors */
  --colors-bg-default: var(--colors-gray-dark);
  --colors-bg-default-rgb: var(--colors-gray-dark-rgb);
  --colors-bg-inverted: var(--colors-white);
  --colors-bg-inverted-rgb: var(--colors-white-rgb);
  /* invert foreground colors */
  --colors-fg-default: var(--colors-gray-light);
  --colors-fg-default-rgb: var(--colors-gray-light-rgb);
  --colors-fg-inverted: var(--colors-gray-dark);
  --colors-fg-inverted-rgb: var(--colors-gray-dark-rgb);
}