@font-face {
  font-family: 'Soehne';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./files/soehne-web-buch.woff2') format('woff2');
}

@font-face {
  font-family: 'Soehne';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('./files/soehne-web-halbfett.woff2') format('woff2');
}

@font-face {
  font-family: 'Soehne';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('./files/soehne-web-kraftig.woff2') format('woff2');
}
