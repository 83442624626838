@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply min-h-full;
}

html, body, #__next {
  @apply flex;
  @apply grow;
}

.wp-block-table table {
  @apply w-full;
  @apply border;
  @apply border-gray-mediumlight;
  @apply border-collapse;
  @apply border-spacing-0;
}

.wp-block-table table th {
  @apply bg-gray-mediumlight;
  @apply p-3;
  @apply text-left;
  @apply uppercase;
  @apply text-xs;
  @apply font-bold;
}

.wp-block-table table tbody td {
  @apply border;
  @apply border-gray-mediumlight;
  @apply p-3;
}

.wp-block-columns {
  @apply grid;
  @apply grid-cols-1;
  @apply gap-7;
  @apply md:grid-flow-col;
  @apply md:auto-cols-fr;
  @apply md:gap-10;
  @apply lg:gap-12;
}